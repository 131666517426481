/* HelpCard.css */

.help-card {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin: 20px auto;
  max-width: 600px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.help-card h2 {
  margin-top: 0;
  color: #4A90E2; /* Light blue color */
  font-size: 1.5rem;
}

.process-graph {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.node {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #4A90E2; /* Light blue color */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.arrow {
  font-size: 2rem;
  color: #4A90E2; /* Light blue color */
}

/* Responsive styles */
@media (max-width: 768px) {
  .process-graph {
    flex-direction: column;
  }

  .arrow {
    transform: rotate(90deg);
    margin: 10px 0;
  }
}