/* BusinessPartnerForm.css */
.business-partner-form {
  padding: 20px;
}

.bp-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.bp-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
}

.bp-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.bp-card-body p {
  margin: 5px 0;
}