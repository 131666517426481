/* MainContent.css */
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: hidden;
}

.add-carrier-container {
  position: fixed;
  top: 80px; /* Adjust based on the height of the header */
  width: 100%;
  background-color: #f8f9fa; /* Adjust background color as needed */
  padding: 20px; /* Adjust padding as needed */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better separation */
  z-index: 1000; /* Ensure it is above other content */
}

.carrier-cards-container {
  margin-top: 140px; /* Adjust based on the height of the header and add-carrier-container */
  flex: 1;
  overflow-y: auto;
  padding: 20px; /* Adjust padding as needed */
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust gap between cards as needed */
  background-color: #ffffff; /* Adjust background color as needed */
}