/* AddUserForm.css */

.add-user-form {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-user-form h2 {
  margin-top: 0;
  font-size: 1.5em;
  color: #333;
  text-align: center;
}

.add-user-form .form-group {
  margin-bottom: 15px;
  position: relative; /* Ensure the icon is positioned correctly */
}

.add-user-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.add-user-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.add-user-form .password-group {
  display: flex;
  align-items: center;
  position: relative; /* Ensure the icon is positioned correctly */
}

.add-user-form .password-group input {
  width: 100%; /* Ensure the input takes the full width */
  padding-right: 30px; /* Add padding to the right to make space for the icon */
}

.add-user-form .password-toggle-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #888;
}

.add-user-form .error {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}

.add-user-form .success {
  color: green;
  font-size: 0.875rem;
  margin-top: 5px;
}

.add-user-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1em;
  cursor: pointer;
}

.add-user-form button:hover {
  background-color: #0056b3;
}

/* Responsive styles */
@media (max-width: 768px) {
  .add-user-form {
    padding: 15px;
    margin: 15px auto;
    max-width: 90%; /* Ensure full width on smaller screens with 5% margin on both sides */
  }

  .add-user-form h2 {
    font-size: 1.25rem;
  }

  .add-user-form input {
    padding: 8px;
  }

  .add-user-form button {
    padding: 8px;
    font-size: 0.875rem;
  }

  .form-group input,
  .form-group select {
    padding: 12px; /* Increase padding for better touch experience */
  }

  button {
    padding: 12px; /* Increase padding for better touch experience */
  }
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #007bff;
  outline: none;
}

.password-group {
  display: flex;
  align-items: center;
}

.password-toggle-icon {
  margin-left: 10px;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 0.875em;
}