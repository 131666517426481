.carrier-card {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  max-width: 500px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.carrier-card h3 {
  margin-top: 0;
  color: #4A90E2; /* Light blue color */
  font-size: 1.5rem;
}

.carrier-card p {
  margin: 10px 0;
  font-size: 1rem;
}

.carrier-card .actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.carrier-card .actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.carrier-card .actions button.enable {
  background-color: #4CAF50; /* Green */
  color: white;
}

.carrier-card .actions button.disable {
  background-color: #f44336; /* Red */
  color: white;
}

.carrier-actions {
  display: flex;
  gap: 8px;
}

.carrier-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.5rem; /* Increase font size for icons */
  background-color: transparent; /* Make background transparent */
}

.carrier-actions button:hover {
  background-color: #ddd;
}

/* Add styles for the sticky add carrier container */
.add-carrier-container {
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  padding: 16px;
  border-bottom: 1px solid #ccc;
  z-index: 1000;
}

.add-carrier-container h2 {
  margin: 0 0 16px 0;
}

.add-carrier-container ol {
  padding-left: 20px;
}

.add-carrier-container li {
  margin-bottom: 10px;
}

.add-carrier-container input {
  margin-left: 10px;
}

.add-carrier-container button {
  margin-left: 10px;
}

/* Target the <strong> elements within the CarrierCard */
.carrier-card strong {
  font-size: 0.85em; /* Adjust the font size as needed */
}

/* Optionally, you can also target the container if needed */
.carrier-card-container strong {
  font-size: 0.85em; /* Adjust the font size as needed */
}

/* Responsive styles */
@media (max-width: 768px) {
  .carrier-card {
    padding: 15px;
    margin: 15px auto;
  }

  .carrier-card h3 {
    font-size: 1.25rem;
  }

  .carrier-card p {
    font-size: 0.875rem;
  }

  .carrier-card .actions button {
    padding: 8px 15px;
    font-size: 0.875rem;
  }
}

.carrier-card .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.carrier-card .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.carrier-card .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.carrier-card .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.carrier-card input:checked + .slider {
  background-color: #2196F3;
}

.carrier-card input:checked + .slider:before {
  transform: translateX(26px);
}