/* BottomMenu.css */
.bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #ddd;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  z-index: 1000;
  overflow-x: auto;
  white-space: nowrap;
}

.bottom-menu.hidden {
  display: none;
}

.bottom-menu.visible {
  display: flex;
}

.bottom-menu-item {
  flex: 1;
  text-align: center;
}

.bottom-menu-item button {
  background: none;
  border: none;
  color: #333;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
}

.bottom-menu-item button:hover {
  background-color: #f0f0f0;
}

.bottom-menu-item button:focus {
  outline: none;
}

.overlay {
  position: fixed;
  top: 80px; /* Adjust based on the height of the header */
  left: 0;
  width: 100%;
  height: calc(100% - 80px); /* Adjust based on the height of the header */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Add background color for overlay */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align to the top */
  z-index: 1000; /* Ensure it is above other content */
}

.add-business-partner-form {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-business-partner-form h2 {
  margin-top: 0;
  font-size: 1.5em;
  color: #333;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1em;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.content-container {
  padding-bottom: 60px; /* Ensure space for the bottom menu */
  margin-top: 80; /* Adjust based on the height of the header */
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: auto;
  position: absolute;

  z-index: 1; /* Ensure it is above other elements */
}

.bp-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  width: 100%;
  background-color: #ffffff; /* Adjust background color as needed */
  padding: 20px; /* Adjust padding as needed */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better separation */
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 20px;
  z-index: 999; /* Ensure it is above other elements */
}

.form-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px; /* Adjust width as needed */
  margin-top: 20px; /* Adjust margin as needed */
}

@media (max-width: 768px) {
  .bp-container {
    margin: 10px;
    padding: 10px;
  }

  .bottom-menu-item button {
    font-size: 12px;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .bp-container {
    margin: 5px;
    padding: 5px;
  }

  .bottom-menu-item button {
    font-size: 10px;
    padding: 6px;
  }
}