/* UserCard.css */
.user-card {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin: 20px auto;
  max-width: 500px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: background-color 0.3s;
}

.user-card h3 {
  margin-top: 0;
  color: #4A90E2; /* Light blue color */
  font-size: 1.5rem;
}

.user-card p {
  margin: 10px 0;
  font-size: 1rem;
}

.user-card .actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.user-card .actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.user-card .actions button.enable {
  background-color: #4CAF50; /* Green */
  color: white;
}

.user-card .actions button.disable {
  background-color: #f44336; /* Red */
  color: white;
}

.user-card.enabled {
  background-color: #d4edda; /* Light green */
}

.user-card.disabled {
  background-color: #f8d7da; /* Light reddish */
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

/* Responsive styles */
@media (max-width: 768px) {
  .user-card {
    padding: 15px;
    margin: 15px auto;
  }

  .user-card h3 {
    font-size: 1.25rem;
  }

  .user-card p {
    font-size: 0.875rem;
  }

  .user-card .actions button {
    padding: 8px 15px;
    font-size: 0.875rem;
  }
}