.driver-card {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin: 20px auto;
  max-width: 500px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative; /* Add relative positioning for the badge */
}

.driver-card h3 {
  margin-top: 0;
  color: #4A90E2; /* Light blue color */
  font-size: 1.5rem;
}

.driver-card p {
  margin: 10px 0;
  font-size: 1rem;
}

.driver-card .actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.driver-card .actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.driver-card .actions button.enable {
  background-color: #4CAF50; /* Green */
  color: white;
}

.driver-card .actions button.disable {
  background-color: #f44336; /* Red */
  color: white;
}

.driver-info {
  margin-bottom: 16px;
}

.status-badge {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.5rem; /* Adjust the size of the badge */
  border: 2px solid; /* Add border to the badge */
  border-radius: 50%; /* Make the badge circular */
  padding: 4px; /* Add padding to the badge */
  background-color: white; /* Set background color to white */
  display: inline-flex; /* Align the icon inside the badge */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  width: 24px; /* Set a fixed width for the badge */
  height: 24px; /* Set a fixed height for the badge */
}

.password-container {
  display: flex;
  align-items: center;
}

.password-container input {
  flex-grow: 1;
}

.toggle-password {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-left: 8px;
}

.driver-actions {
  display: flex;
  gap: 8px;
}

.driver-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.5rem; /* Increase font size for icons */
  background-color: transparent; /* Make background transparent */
}

.driver-actions button.add {
  color: green; /* Add button color */
}

.driver-actions button.enable {
  color: blue; /* Enable button color */
}

.driver-actions button.disable {
  color: red; /* Disable button color */
}

.driver-actions button:hover {
  background-color: #ddd;
}

.success-message {
  color: green;
  margin-top: 16px;
}

.error-message {
  color: red;
  margin-top: 16px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .driver-card {
    padding: 15px;
    margin: 15px auto;
  }

  .driver-card h3 {
    font-size: 1.25rem;
  }

  .driver-card p {
    font-size: 0.875rem;
  }

  .driver-card .actions button {
    padding: 8px 15px;
    font-size: 0.875rem;
  }
}