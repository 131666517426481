/* App.css */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* comentario */
.App-header {
  background-color: #e38a16;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.api-key-container,
.search-container,
.driver-card {
  width: 90%; /* Set the width to 90% */
  max-width: 600px; /* Set a max width for larger screens */
  margin: 0 auto; /* Center the container */
}

.search-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.search-container input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1; /* Allow the input to grow and take available space */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
:root {
  --header-height: 80px; /* Adjust based on the actual height of the header */
}
/* Fix the header at the top of the viewport */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #292fea; /* Set the desired background color */
  z-index: 1000; /* Ensure the header is above other content */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  color: white; /* Ensure the text color is white */
  height: var(--header-height) /* Set a fixed height for the header */
}

.header h1 {
  margin: 0;
  font-size: 2rem; /* Smaller font size for the title */
}

.logout-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 1.5rem; /* Adjust icon size */
  color: #fff; /* White color for the icon */
}

/* Add padding to the top of the main content to avoid overlap with the header */
.main-content-wrapper {
  padding-top: var(--header-height); /* Adjust based on the height of the header */
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.api-key-container {
  margin-top: 20px; /* Ensure the dropdown is not hidden behind the header */
}

.driver-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin: 16px auto; /* Center the card */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.driver-info {
  margin-bottom: 16px;
}

.driver-actions {
  display: flex;
  gap: 8px;
}

.driver-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.5rem; /* Increase font size for icons */
  background-color: transparent; /* Make background transparent */
}

.driver-actions button.add {
  color: green; /* Add button color */
}

.driver-actions button.enable {
  color: blue; /* Enable button color */
}

.driver-actions button.disable {
  color: red; /* Disable button color */
}

.driver-actions button:hover {
  background-color: #ddd;
}

@media (max-width: 768px) {
  .header {
    padding: 10px; /* Adjust padding for smaller screens */
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }

  .header h1 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .logout-icon {
    font-size: 1.2rem; /* Adjust icon size for smaller screens */
  }

  .api-key-container,
  .search-container,
  .driver-card {
    max-width: 90%; /* Ensure full width on smaller screens with 5% margin on both sides */
  }

  .main-content-wrapper {
    padding-top: var(--header-height) /* Adjust based on the height of the header */
  }

  .bottom-menu {
    padding: 8px 0;
  }

  .bottom-menu-item {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 10px;
    font-size: 1.2rem; /* Adjust font size for very small screens */
  }

  .header h1 {
    font-size: 1.5rem; /* Adjust font size for very small screens */
  }

  .api-key-container,
  .search-container,
  .driver-card {
    max-width: 90%; /* Ensure full width on very small screens with 5% margin on both sides */
  }
}

.main-content {
  margin-left: 0; /* Reset margin when sidebar is collapsed */
  transition: margin-left 0.3s;
  padding-top: 50px; /* Add padding to avoid overlap with the header */
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; 
}

.main-content.shifted {
  margin-left: 250px;
}

.wide-input {
  width: 300px; /* Set the desired width */
}

.user-profile-name {
  font-size: 0.8em; /* Reduce the font size */
  margin-top: 10px;
}

/* Bottom menu styles */
.bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}

.bottom-menu-item {
  text-align: center;
  flex: 1;
}

.bottom-menu-item a {
  text-decoration: none;
  color: #000;
}

.bottom-menu-item a:hover {
  color: #007aff; /* Apple blue color */
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.business-partner-card {
  background-color: #fff;
  padding: 20px;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 600px;
  width: 90%;
}

@media (max-width: 768px) {
  .business-partner-card {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .business-partner-card {
    max-width: 90%;
  }
}