/* Header.css */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #292fea; /* Set the desired background color */
  z-index: 1000; /* Ensure the header is above other content */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  color: white; /* Ensure the text color is white */
  height: 80px; /* Set a fixed height for the header */
}

.header h1 {
  margin: 0;
  font-size: 2rem; /* Smaller font size for the title */
}

.logout-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 1.5rem; /* Adjust icon size */
  color: #fff; /* White color for the icon */
}

.user-profile-name {
  font-size: 0.8em; /* Reduce the font size */
  margin-top: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .header {
    padding: 10px; /* Adjust padding for smaller screens */
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }

  .header h1 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .logout-icon {
    font-size: 1.2rem; /* Adjust icon size for smaller screens */
  }
}